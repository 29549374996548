
$navbar-height: 100px;
$hero-height-max: 260px;
$hero-height-min: 130px;
$hero-height: 30vh;

$medicos-black: #3f3f3f;
$medicos-light-blue: #0578ad;
$medicos-logo-light-blue: #5daed3;
$medicos-logo-blue: #0074a3;
$medicos-logo-light-green: #c3dda4;

$navbar-bg-color-transparent: rgba(0, 46, 64, 0.5);
$navbar-bg-color: rgb(0, 46, 64);
$slanty-color: #e9ecef;


// body {
//     font-family: 'Noto Sans', sans-serif;
// }


/*** Highlight text color ***/
::selection {
    background-color: $medicos-logo-light-blue;
    color: #fff;
}

/* Global Button Styling */
.btn-primary{
    background-color: $medicos-logo-blue;
    border-color: $medicos-logo-light-blue;
    color: white;
    /* transition: 500ms; */
}

.btn:hover{
    background-color: $medicos-logo-light-blue;
    color: white;
}

.underline{
    text-decoration: underline;
}

.navbar-solid {
    background-color: $navbar-bg-color !important;
    box-shadow: 2px 8px 16px 0px rgba(0,0,0,0.5);
}

.navbar-medicos {
    background-color: $navbar-bg-color-transparent;
    position: fixed;
    z-index: 5;
    width: 100vw;
    color: white;
    padding-right: 2rem;
    transition: 500ms;
}

/* style this for desktop / mobile */
nav.navbar-medicos img {
    height: 47px;
}

/* Medium devices (tablets, 768px and up)
    i.e. desktop
*/
@media (min-width: 768px) {
    nav.navbar-medicos img {
        height: 65px;
    }
}

.diagonal{
    padding-bottom: 10rem;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 5vw), 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 5vw), 0 100%);
}

.diagonal-bottom{
    padding-top: 5rem;
    padding-bottom: 5rem;
    /* clip-path: polygon(0 0, 100% 15%, 100% 85%, 0 100%); */
    clip-path: polygon(0 0, 100% 15%, 100% 85%, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 15%, 100% 85%, 0 100%);
}

.slanty-gray {
    background-color: $slanty-color;
}

.slanty-top {
    background-color: white;
    width: 100%;
    height: 2rem;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%);

}

.slanty-bottom {
    background-color: white;
    width: 100%;
    height: 2rem;
    clip-path: polygon(0 100%, 100% 100%, 100% 0);
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 0);
}


.nav-scrolled{
    background-color: $navbar-bg-color;
    /* position: fixed;
    z-index: 5;
    width: 100vw;
    color: white;
    padding-right: 2rem; */
    /* transition: 500ms; */
    box-shadow: 2px 8px 16px 0px rgba(0,0,0,0.5);
}

/* html:not([data-scroll='0']) .navbar-medicos {
    background-color: rgba(0, 46, 64, 1);
} */


nav.navbar-medicos li>a {
    color:rgba(255, 255, 255, 0.788);
}

nav.navbar-medicos li > a:hover{
    /* border: 1px solid var(--medicos-logo-blue);
    padding: 7px !important;
    border-radius: 5px; */
    color: white;
}

footer {
    margin: 0;
    background-color: #052936;
    color: white;
    box-shadow: 0px -3px 16px 0px rgba(0,0,0,0.5);
}

.company-name-header {
    color: $medicos-logo-light-blue;
}

h1 {
    color: $medicos-logo-blue;
}


/* Hero parallax */
.parallax-window {
    padding-top: $navbar-height;
    height: calc(#{$navbar-height} + #{$hero-height});
    min-height: calc(#{$navbar-height} + #{$hero-height-min});
    max-height: calc(#{$navbar-height} + #{$hero-height-max});
    background: transparent;
    position: relative;
    z-index: 2;
}

header.parallax-window h1 {
    color: white;
}

header.main-hero {
    height: calc(#{$navbar-height} + #{$hero-height});
    min-height: calc(#{$navbar-height} + #{$hero-height-min});
    max-height: calc(#{$navbar-height} + #{$hero-height-max});
    position: relative;
    overflow: hidden;
}

header.main-hero > video {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    object-fit: cover;
    background-color: #000;
}

/* IE and Edge specific styling to enable full width video in header */
@mixin fit-video-microsoft(){
    header.main-hero > video {
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @media (max-width: 768px) {
        header.main-hero > video {
            transform: translate(-50%, -50%) scaleY(1.5) ;
        }
    }
    @media (max-width: 512px) {
        header.main-hero > video {
            transform: translate(-50%, -50%) scaleY(2) ;
        }
    }
}

/* hack for Edge, as it does not support object-fit on video elements */
@supports (-ms-ime-align:auto) {
    @include fit-video-microsoft();
}
//  hack for shitty IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @include fit-video-microsoft();
}


header.main-hero > div {
    padding-top: $navbar-height;
    z-index: 1;
}

header.main-hero h1 {
    color: white;
}

header.main-hero h2 {
    color: $medicos-logo-light-blue;
}

header {
    box-shadow: 2px 8px 16px 0px rgba(0,0,0,0.5);
}

.img-fancy {
    border-radius: 16rem 2rem 2rem .25rem;
    /* border-radius: 2rem 16rem 2rem .25rem; */
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.45);
    max-width: 100%;
}

/* image to be added to general images in info pages */
.img-shadow {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.45);
}

.carousel-fancy {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.45);
    border-radius: 1rem;
}

section.donning img {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.45);
    border-radius: 1rem;
}

section.donning > div.img-right h2 {
    margin-right: -1rem;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    section.donning > div.img-right > div.row {
        text-align: right;
    }
    section.donning > div.img-right h2 {
        margin-right: -1rem;
    }
}

section.donning h2 {
    margin-left: -1rem;
}


.bg-medicos-blue{
    background-color: $medicos-logo-blue;
}

.close {
    color: white;
    opacity: 1;
}

.close:hover{
    color: $medicos-logo-light-blue;
    opacity: 1;
}

.wm-stage {
    width: 75%;
    max-width: 1600px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5rem;
}

section.mdmask {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

section.mdmask.logo-container img {
    height: 100%;
    margin: 0 16px;
}

section.mdmask.logo-container {
     display: flex;
     justify-content: space-evenly;
     height: 150px;
}

/* IE and Edge specific styling to enable centering */
@mixin center-logos(){
    section.mdmask.logo-container {
        justify-content: space-around;
    }
}


/* hack for Edge to use space-around, as it does not support space-evenly */
@supports (-ms-ime-align:auto) {
    @include center-logos();
}

//  hack for shitty IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @include center-logos();
}


@media (max-width: 575.98px) {
    section.mdmask.logo-container {
        height: 120px;
   }
}

section.mdmask ul {
    /* list-style-position: outside; */
    /* padding-left: 0; */
    line-height: 2;
}

.location-map{
    width: 100%;
}

.alert{
    box-shadow: 2px 8px 16px 0px rgba(0,0,0,0.5);
}

/* header.covid19 h1 {
    font-size: 4rem;
    font-weight: 400;
    margin: 5rem 1rem;
    color: white;
    font-family: 'Anton', sans-serif;
} */

section.covid19.tile-links {
    padding-left: 0;
    padding-right: 0;
}

section.covid19 ul{
    line-height: 2;
}

section.covid19 h1 {
    color:rgb(209, 6, 6);
}

form#feedback-form img {
    max-height: 120px;
}

section.covid19 img.ochsner-logo {
    width: 483px;
    height: 53px;
    max-width: 100%;
}

section.covid19 div.card {
    border-radius: 16px;
    text-align: center;
    box-shadow: 0 8px 8px 0 rgba(0,0,0,0.2);
    margin: 1.5rem;
    user-select: none;
}

section.covid19 div.card > img:first-child {
    border: 1px solid #e0e0e0;
    border-radius: 16px 16px 0 0;
    box-shadow: 0 14px 8px -8px rgba(0,0,0,0.5);
    padding: 10px;
}

section.covid19.tile-links div.card {
    width: 18rem;
}

section.covid19.tile-links img.pdf-icon-sticker {
    display: block;
    position: absolute;
    bottom: 19px;
    right: 16px;
    height: 40px;
}

// Header card used for MD Shield call to action
section.covid19.tile-links div.head-card {
    width: unset;
}

div.card.head-card img {
    width: 100%;
    max-width: 26rem;
    padding: .5rem;
    display: inline-block;
    padding-bottom: 0 !important;
}

#covid-clear {
    h2 {
        color: $medicos-logo-blue;
    }
    
    .level-container{
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.45);
        position: relative;
        border: 2px solid $medicos-logo-blue;
        border-left: 60px solid $medicos-logo-blue;
        padding: 1rem;
        color: $medicos-black;
        display: inline-block;
        margin-bottom: 1.5rem;

       >h3 {
        color: white;
        text-transform: uppercase;
        letter-spacing: 3px;
        
        position: absolute;
        top: 148px;
        left: 0;
        margin-left: -48px;
        
        transform-origin: 0 0;
        transform: rotate(270deg);    
       }

       .level-body {
           h3 {
               color: $medicos-logo-blue;
               margin-bottom: 1rem;
           }
       }
       
       li {
           margin-bottom: 0.75rem;
        //    line-height: 1.4;
       }
    }
}

/*
img.svg-icon {
}
*/



/* cut and paste of outline button */
.btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
  }

  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }

  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }

  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
  }

  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }

  .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
