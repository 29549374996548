// Our google font of choice
@import url(https://fonts.googleapis.com/css?family=Noto+Sans&display=swap);

// Bootsrap customizations
$font-family-sans-serif: "Noto Sans", sans-serif;

// bootstrap
// @import "~bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/bootstrap";

// AOS animation css
@import "../../node_modules/aos/dist/aos.css";

// our custom styles
@import "./new-styles.scss";
